import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';


const httpLink = createHttpLink({
  uri: 'https://gql.flickme.app/v1/graphql', // Replace with your GraphQL API endpoint
});


const authLink = setContext((_, { headers }) => {
  // Add your custom header here
  const customHeaders = {
    'x-hasura-role': 'guest', // Replace with the actual role value you want to set
  };

  return {
    headers: {
      ...headers,
      ...customHeaders,
    },
  };
});

const wsLink = new WebSocketLink({
  uri: `wss://gql.flickme.app/v1/graphql`, // Use your WebSocket endpoint
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        'x-hasura-role': 'guest'
        // ...authLink().headers, // Include headers from the authLink
      },
    },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink // Use the authLink with the HTTP link for regular queries and mutations
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
    <App />
    </ ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
